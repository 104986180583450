import * as React from 'react';

import { graphql } from 'gatsby';

import TemplatePage from 'components/pages/templates/TemplatePage';

const Template = ({
  data,
}) => (
  <TemplatePage
    title="HR Dashboard Web App Template | HRIS Website Design | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/hr-web-app/"
    metaDescription="Uizard's HR management or HRIS template is here to save the day! Come one step closer to the best HRIS software design with Uizard."
    description="
    h2:A HRIS website design template for your own HR software design project
    <br/>
    Looking to design your own HRIS web app? Our brand-new HR software web app design template is here to streamline your design journey. Built with a stunning, minimalist aesthetic, our HRIS web app template is ready to go out of the box. Simply sign up to Uizard, select the template, and begin <a:https://uizard.io/prototyping/>UI prototyping</a> your own HR web app design.
    <br/>
    h3:Work with your team, or create your HR web app design template solo
    <br/>
    If there's one thing we take seriously, it's collaboration. If you are designing your own human resources web app and want to get your team involved in the design flow, simply share a link to your Uizard project and begin collaboration in real-time. You can even share your mockup with potential customers or clients to really test your web app concept.
    <br/>
    h3:How to get the most out of our HRIS web app template?
    <br/>
    In a world where having the best talent is the key to business success, having solid HR processes is crucial. We crafted this HRIS template for you to design the best HRIS software for your use case. This HRIS template comes with diagrams and pre-crafted screens but, best of all, the entire <a:https://uizard.io/templates/>UI template</a> can be curated and customized to really suit your vision or branding!
    "
    pages={[
      'People: a members page',
      'A wellness check-in page',
      'A time-off management screen',
      'An application-tracker page for hiring',
      'A dashboard for HR metrics',
      'And more!',
    ]}
    projectCode="RKjW1BbJBJtEEjMLVeQW"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of HRIS web app: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of HRIS web app: settings screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of HRIS web app: time-off management screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of HRIS web app: application-tracker screen"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of HRIS web app template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/hr-web-app/HR-Web-App-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/hr-web-app/HR-Web-App-People.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(relativePath: { eq: "templates/hr-web-app/HR-Web-App-Vacations.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/hr-web-app/HR-Web-App-Vacations.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/hr-web-app/HR-Web-App-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
